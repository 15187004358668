<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="list1"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="ข้อมูลสรุปการ X-Ray.xls"
        :header="'ข้อมูลสรุปการ X-Ray'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Download ข้อมูลสรุปการ X-Ray
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["list1"],
  data() {
    return {
      json_fields: {
        ชื่อชุมชน: "village",
        ชื่อสถานี: "station",
        จังหวัด: "province",
        ภาค: "area",
        "ประชากร ทร.14": "sumPeople14",
        จำนวนครัวเรือน: "sumHouse",
        จำนวนประชากรจริง: "people1",
        จำนวนครัวเรือนจริง: "people2",
        "MOU ครัวเรือน": "mou1",
        "จำนวนคุ้ม (คุ้ม)": "people3",
        "เป้าหมายการ x-ray ในชุมชน": "xray1",
        "จำนวนการ x-ray": "sumAmt",
        // "ร้อยละการ x-ray": "percentXray",
        พบสารเสพติด: "found",
        ไม่พบสารเสพติด: "notFound",
        เข้ารับการบำบัด: "goin",
        ไม่เข้ารับการบำบัด: "notgoin",
        "จำนวนสมาชิกคุ้ม": "gangsAmt",
        // "พบสารเสพติดครั้งที่ 2": "urine2Found",
        // "ไม่พบสารเสพติดครั้งที่ 2": "urine2NotFound",
        // "พบสารเสพติดครั้งที่ 3": "urine3Found",
        // "ไม่พบสารเสพติดครั้งที่ 3": "urine3NotFound",
        // "พบสารเสพติดครั้งที่ 4": "urine4Found",
        // "ไม่พบสารเสพติดครั้งที่ 4": "urine4NotFound",
        // "พบสารเสพติดครั้งที่ 5": "urine5Found",
        // "ไม่พบสารเสพติดครั้งที่ 5": "urine5NotFound",
        // "พบสารเสพติดครั้งที่ 6": "urine6Found",
        // "ไม่พบสารเสพติดครั้งที่ 6": "urine6NotFound",
        // "พบสารเสพติดครั้งที่ 7": "urine7Found",
        // "ไม่พบสารเสพติดครั้งที่ 7": "urine7NotFound",
        // "พบสารเสพติดครั้งที่ 8": "urine8Found",
        // "ไม่พบสารเสพติดครั้งที่ 8": "urine8NotFound",
        // "พบสารเสพติดครั้งที่ 9": "urine9Found",
        // "ไม่พบสารเสพติดครั้งที่ 9": "urine9NotFound",
        // "พบสารเสพติดครั้งที่ 10": "urine10Found",
        // "ไม่พบสารเสพติดครั้งที่ 10": "urine10NotFound",
        // "พบสารเสพติดครั้งที่ 11": "urine11Found",
        // "ไม่พบสารเสพติดครั้งที่ 11": "urine11NotFound",
        // "พบสารเสพติดครั้งที่ 12": "urine12Found",
        // "ไม่พบสารเสพติดครั้งที่ 12": "urine12NotFound",
        // "พบสารเสพติดครั้งที่ 13": "urine13Found",
        // "ไม่พบสารเสพติดครั้งที่ 13": "urine13NotFound",
        // "พบสารเสพติดครั้งที่ 14": "urine14Found",
        // "ไม่พบสารเสพติดครั้งที่ 14": "urine14NotFound",
        // "พบสารเสพติดครั้งที่ 15": "urine15Found",
        // "ไม่พบสารเสพติดครั้งที่ 15": "urine15NotFound",
        // "พบสารเสพติดครั้งที่ 16": "urine16Found",
        // "ไม่พบสารเสพติดครั้งที่ 16": "urine16NotFound",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
